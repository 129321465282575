import axios from 'axios';

export async function call (method: 'POST' | 'GET' | 'DELETE' | 'PUT', url: string, params: any = {}, successFn?: Function | null, errorFn?: Function | null, isFile: boolean = false) {
const access_token: string = localStorage.getItem('accessToken') as string;
const userId: string = localStorage.getItem('userId') as string;

url = import.meta.env.VITE_SERVER_LINK + url;
const headers = {
    Authorization: 'Bearer ' + access_token,
    'X-User-Id': userId,
    'Content-Type': isFile ? 'multipart/form-data' : 'application/json',
}
const request = method === 'POST' || 'DELETE' ? { data: params } : { params }
return axios({ method, url, headers, ...request })
    .then((r: any) => Promise.resolve(r.data))
    .catch((e: any) => {
        console.error(e);
        return Promise.reject(e?.response?.data?.message)
    })
}


const API = {
    // вход/регистрация
    getVerifyCode: async function (dto: any) {
        return call('POST', `/auth/login`, dto);
    },
    getVerifyCodeRelative: async function (dto: any) {
        return call('POST', `/auth/sendCode`, dto);
    },
    verifyCode: async function (dto: any) {
        return call('POST', `/auth/verifyCode`, dto);
    },

    // получение данных пользователя
    fetchUserData: async function (phone: string) {
        return call('GET', `/users/${phone}`);
    },
    // questions main survey
    addQuestions: async function (dto: any) {
        return call('POST', `/questions/add`, dto);
    },
    editQuestion: async function (_id: string, dto: any) {
        return call('PUT', `/questions/${_id}`, dto);
    },
    deleteQuestion: async function (_id: string) {
        return call('DELETE', `/questions/${_id}`);
    },
    // users
    fetchUsers: async function (params) {
        const phone = params.phone !== undefined ? `&phone=${params.phone}` : ``
        return call('GET', `/users?first=${params.first}&rows=${params.rows}${phone}`);
    },
    getUserByPhone: async function (phone: string) {
        return call('GET', `/users/${phone}`);
    },
    addUser: async function (phone: string, role: string, goal: string) {
        return call('POST', `/users/add`, { phone, role, goal });
    },
    // сохранение новых данных
    saveData: async function (_id: string, dto: any) {
        return call('POST', `/users/saveLastData/${_id}`, dto);
    },
    savePushToken: async function (_id: string, pushToken: string) {
        return call('POST', `/users/savePushToken/${_id}`, {pushToken});
    },
    // admins
    usersInfo: async function () {
        return call('GET', `/admin/statsinfo`);
    },

    // Получение данных главного опроса
    fetchSurveyData: async function (_id: string) {
        return call('GET', `/surveys/user/${_id}`);
    },
    // Получение данных главного опроса
    fetchRegSurveyData: async function (_id: string) {
        return call('GET', `/regsurveys/${_id}`);
    },
    // Получение данных главного опроса
    fetchRegSurveyDataLast: async function (_id: string) {
        return call('GET', `/regsurveys/last/${_id}`);
    },
    // 
    submitSurvey: async function (dto: any) {
        return call('POST', `/surveys/add`, dto);
    },

    // Получение списка вопросов
    fetchQuestions: async function () {
        return call('GET', '/questions');
    },

    // Получение данных периодических опросов
    fetchSurveys: async function (_id: string) {
        return call('GET', `/regsurveys/${_id}`);
    },

    // Получение данных родственников пользователя
    fetchRelativesData: async function (phone: string) {
        return call('GET', `/relatives/${phone}`);
    },

    // Отправка периодического опроса
    RegsurveysAdd: async function (dto: any) {
        return call('POST', `/regsurveys/add`, dto);
    },

    // контроль употребления
    resetLastData: async function (_id: string) {
        return call('POST', `/users/resetLastData/${_id}`);
    },
    onVerge: async function (_id: string) {
        return call('POST', `/users/verge/${_id}`);
    },
    // 

    // Родственники
    // Добавление
    addRelative: async function (dto: any) {
        return call('POST', `/relatives/create`, dto);
    },
    // удаление
    deleteRelative: async function (dto: any) {
        return call('POST', `/relatives/delete`, dto);
    },
    
    // admin
    
    saveRole: async function (dto: any) {
        return call('POST', `/admin/editrole`, dto);
    },
    saveExpenses: async function (dto: any) {
        return call('POST', `/admin/editexpensessum`, dto);
    },
    stopDrinking: async function (_id: string) {
        return call('POST', `/admin/stopdrinking/${_id}`);
    },

}

export default API;