<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import Sidebar from 'primevue/sidebar'
const props = defineProps({
  isAndroid: {
    type: Boolean,
    default: false,
    required: true
  },
  isIphone: {
    type: Boolean,
    default: false,
    required: true
  }
})

const show = ref<boolean>(true)
const { localStorage } = window
const visibleBottom = ref(false)
const needHelp = ref(false)
function hide() {
  show.value = false
  localStorage.setItem('showPwaBanner', show.value.toString())
}
onBeforeMount(() => {
  if (localStorage.getItem('showPwaBanner')) {
    show.value = !localStorage.getItem('showPwaBanner')
  }
})
</script>
<template>
  <div
    v-if="show"
    class="m-app"
  >
    <div class="m-app-top">
      <Button
        icon="pi pi-times"
        class="m-app-top-close"
        @click="hide"
      />
      <div class="m-app-top-content">
        <div class="m-app-top-content-title">
          Установить приложение
        </div>
        <p>Для удобного доступа к контенту</p>
      </div>
      <div class="m-app-top-icon">
        <Button
          icon="pi pi-download"
          @click="visibleBottom = true"
        />
      </div>
    </div>
    <Sidebar
      :class="['m-app-bottom', 'z-999', { 'm-app-bottom--ios': isIphone }, { 'm-app-bottom--android': isAndroid }]"
      position="bottom"
      v-model:visible="visibleBottom"
      @hide="needHelp = false"
    >
      <template #header>
        <div class="text-xl">Установите приложение</div>
      </template>
      <div class="m-app-bottom-content">
        <!-- iOS -->
        <template v-if="isIphone">
          <div v-if="needHelp">
            Чтобы установка работала корректно необходимо открыть сайт в Safari. Для этого нажмите на иконку открытия браузера в правом нижнем углу.
            <div class="arrow-animated arrow-animated--rotate">
              <i class="pi pi-arrow-down" />
            </div>
          </div>
          <div v-else>
            <img
              src="@/assets/iphone.svg"
              class="m-app-bottom-content-img"
              alt=""
            >
            <p>Установите приложение на iPhone: нажмите
              <svg
                width="15"
                height="19"
                viewBox="0 0 15 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.9375 12.624C8.33594 12.624 8.66406 12.2959 8.66406 11.9131V4.0459L8.60156 2.88184L9.08594 3.45215L10.1875 4.62402C10.3125 4.76465 10.4922 4.83496 10.6719 4.83496C11.0234 4.83496 11.3125 4.57715 11.3125 4.20996C11.3125 4.02246 11.2422 3.88184 11.1094 3.74902L8.48438 1.22559C8.30469 1.03809 8.13281 0.975586 7.9375 0.975586C7.75 0.975586 7.57812 1.03809 7.39844 1.22559L4.77344 3.74902C4.64062 3.88184 4.5625 4.02246 4.5625 4.20996C4.5625 4.57715 4.85156 4.83496 5.20312 4.83496C5.38281 4.83496 5.57031 4.76465 5.69531 4.62402L6.78906 3.45215L7.28125 2.88184L7.21875 4.0459V11.9131C7.21875 12.2959 7.54688 12.624 7.9375 12.624ZM3.44531 18.8506H12.4375C14.125 18.8506 15 17.9834 15 16.3193V8.62402C15 6.95996 14.125 6.09277 12.4375 6.09277H10.3203V7.62402H12.3438C13.0625 7.62402 13.4688 7.99902 13.4688 8.75684V16.1865C13.4688 16.9443 13.0625 17.3193 12.3438 17.3193H3.53906C2.8125 17.3193 2.41406 16.9443 2.41406 16.1865V8.75684C2.41406 7.99902 2.8125 7.62402 3.53906 7.62402H5.57812V6.09277H3.44531C1.75781 6.09277 0.882812 6.95996 0.882812 8.62402V16.3193C0.882812 17.9834 1.75781 18.8506 3.44531 18.8506Z"
                  fill="#2EA7E5"
                />
              </svg>
              внизу Safari и выберите <span class="white-space-nowrap">'На экран «Домой»'</span>
            </p>
            <p
              class="text-400 text-sm mt-1 cursor-pointer"
              @click="needHelp = true"
            >
              Не получается установить?
            </p>
            <div class="arrow-animated">
              <i class="pi pi-arrow-down" />
            </div>
          </div>
        </template>

        <!-- Android -->
        <template v-if="isAndroid">
          <div
            v-if="needHelp"
            class="pt-4"
          >
            <p>Чтобы установка работала корректно необходимо открыть сайт в Chrome.</p>
            <a
              href="https://play.google.com/store/apps/details?id=com.android.chrome"
              class="p-button p-component p-button-secondary justify-content-center gap-2 text-lg"
            >
              <img
                src="@/assets/chrome.svg"
                class="max-w-2rem"
                alt=""
              >
              Скачать Chrome
            </a>
          </div>
          <div v-else>
            <div class="arrow-animated arrow-animated--top">
              <i class="pi pi-arrow-up" />
            </div>
            <img
              src="@/assets/iphone.svg"
              class="m-app-bottom-content-img"
              alt=""
            >
            <p>Установите приложение на Android: нажмите
              <i class="pi pi-fw pi-ellipsis-v" />
              в правом верхнем углу браузера Chrome и выберите <span class="white-space-nowrap font-medium">'Добавить на гл. экран'</span>.
            </p>
            <p>
              В открывшемся окне нажмите <span class="font-medium">"Добавить"</span>
            </p>
            <p
              class="text-400 text-sm mt-1 cursor-pointer"
              @click="needHelp = true"
            >
              Не получается установить?
            </p>
          </div>
        </template>
      </div>
    </Sidebar>
  </div>
</template>
<style lang="scss" scoped>
.m-app {
  top: 0;
  z-index: 999;
  position: sticky;
  color: var(--surface-800);

  &-top {
    gap: 10px;
    display: flex;
    align-items: center;
    background: #fff; // rgba(#36bbb7, .8);
    box-shadow: 0px 4px 50px #d4dae1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 0.75rem;

    &-close {
      width: auto;
      padding: 5px 5px 5px 0;
      color: var(--surface-800);
      border-radius: 50%;
      background: transparent;
      border: 1px solid transparent;

      &:enabled:hover,
      &:enabled:focus {
        background: transparent;
        border-color: transparent;
      }
    }

    &-content {
      width: 100%;

      &-title {
        font-weight: 700;
        font-size: 1rem;
        margin-bottom: 3px;

        @media (min-width: 330px) {
          font-size: 1.06666rem;
        }
      }
    }

    &-icon .pi {
      font-size: 2rem;
    }
  }
}

.m-app-bottom {

  &-content {
    // height: 100%;
    font-size: 17px;
    line-height: 1.5em;
    text-align: center;
    position: relative;
    min-height: 15rem;

    &-img {
      height: 120px;
    }

    .chrome-pwa-img {
      max-width: 200px;
    }
  }
}


.arrow-animated {
  // margin-top: 10px;
  bottom: -10px;
  position: relative;

  .pi {
    animation: arrow_anim 1s infinite;
  }

  &--rotate {
    position: absolute;
    right: 3px;
  }
}

@keyframes arrow_anim {
  0% {
    transform: translateY(0)
  }

  50% {
    transform: translateY(8px)
  }

  100% {
    transform: translateY(0)
  }
}
</style>