// стартовая анкета после регистрации
import { defineStore } from 'pinia';
import { ref } from 'vue';
import API from '@/ts/API.ts';
import { useUserStore } from '@/stores/user'


export const useSurveyStore = defineStore('surveyStore', () => {
  const userStore = useUserStore();

  const mainSurvey = ref({});
  const surveyData = ref({});
  const questions = ref([]);
  const answers = ref([]);
  const survey = ref(false);

  const fetchSurveyData = async () => {
    try {
      const response = await API.fetchSurveyData(userStore.userData._id);
      const fetchedData = response;
      if (fetchedData) {
        // Ваш код обработки данных
        surveyData.value = fetchedData;
        survey.value = true;
      }
    } catch (error) {
      console.error('Error fetching survey data:', error);
    }
  };
  

  const fetchQuestions = async () => {
    try {
      const response = await API.fetchQuestions(localStorage.getItem('userId'));
      const fetchedData = response;
      if (fetchedData) {
        // Ваш код обработки данных
        questions.value = fetchedData;
        answers.value = questions.value.flatMap((question) =>
          question.options.map((option, index) => ({
            // формирование анкеты
            questionId: question._id,
            answerId: index,
            // доп переменные для визуала
            id: `${question._id}-${index}`,
            answerText: option,
            select: false,
            multiple: question.isMultipleChoice,
            selectedAnswers: [],
          }))
        )
      }
    } catch (error) {
      console.error('Error fetching survey data:', error);
    }
  };

  const submitSurvey = async (dto: any) => {
    try {
      await API.submitSurvey(dto);
      survey.value = true;
    } catch (error) {
      console.error('Error submitting survey:', error);
    }
  };

  return {
    mainSurvey,
    surveyData,
    questions,
    answers,
    survey,

    fetchSurveyData,
    fetchQuestions,
    submitSurvey,
  };
});