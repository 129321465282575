<script setup lang="ts">
import { useRouter } from 'vue-router'
const props = defineProps({
  back: {
    type: Boolean,
    default: true
  },
  header: {
    type: String,
    default: ''
  }
})
const router = useRouter()
</script>

<template>
  <div class="flex align-items-center text-900 text-2xl font-bold mb-3">
    <Button
      v-if="props.back"
      icon="pi pi-fw pi-chevron-left"
      text
      style="margin-left: -1rem;"
      @click="router.back()"
    /> 
    {{ props.header }}
  </div>
</template>