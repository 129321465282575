// здесь регуялрные опросы: РОКС и качество жизни
import { defineStore } from 'pinia';
import { ref } from 'vue';
import API from '@/ts/API.ts';
import { useMainStore } from '@/stores'
import { useUserStore } from '@/stores/user.ts';
import { useToast } from 'primevue/usetoast';

export const useRegularSurveysStore = defineStore('regularSurveysStore', () => {
  const store = useMainStore()
  const toast = useToast()
  const userStore = useUserStore();

  const surveys = ref([]);
  const regularSurvey = ref(true);
  const category = ref('');

  const regSurveyOptionsQuality = ref([
    { label: 'Выберите значение', value: 0 },
    { label: 'Очень плохо', value: 1 },
    { label: 'Плохо', value: 2 },
    { label: 'Так себе', value: 3 },
    { label: 'Удовлетворительно', value: 4 },
    { label: 'Хорошо', value: 5 },
    { label: 'Очень хорошо', value: 6 },
    { label: 'Отлично', value: 7 },
    { label: 'Превосходно', value: 8 },
    { label: 'Замечательно', value: 9 },
    { label: 'Прекрасно', value: 10 },
  ]);

  const regSurveyOptionsROCS = ref([
    { label: 'Выберите значение', value: -1 },
    { label: 'Никогда', value: 0 },
    { label: 'Иногда', value: 1 },
    { label: 'Часто', value: 2 },
    { label: 'Всегда', value: 3 },
  ]);

  const questionsQuality = ref([
    { question: 'Физическое благополучие', answer: 0, label: 'ощущение энергичности, отсутствие боли и физических проблем' },
    { question: 'Психологическое/Эмоциональное благополучие', answer: 0, label: 'хорошее самочувствие, удовлетворенность собой' },
    { question: 'Самообслуживание и независимость действий', answer: 0, label: 'выполнение повседневных жизненных задач, принятие собственных решений' },
    { question: 'Работоспособность', answer: 0, label: 'возможность выполнять рабочие и домашние обязанности' },
    { question: 'Межличностное взаимодействие', answer: 0, label: 'способность поддерживать хорошие отношения с семьей, друзьями и группами' },
    { question: 'Социо-эмоциональная поддержка', answer: 0, label: 'доступность людей, которым Вы верите и которые могут предложить помощь и эмоциональную поддержку' },
    { question: 'Общественная и служебная поддержка', answer: 0, label: 'приятные и безопасные соседи, доступность к финансовым, информационным и другим ресурсам' },
    { question: 'Личностная реализация', answer: 0, label: 'наличие чувства уравновешенности, собственного достоинства и солидарности, получения удовольствия от секса, искусства и др.' },
    { question: 'Духовная реализация', answer: 0, label: 'выход за рамки обычной материальной жизни, религиозность, чувство веры' },
    { question: 'Общее восприятие качества жизни', answer: 0, label: 'чувство удовлетворенности и счастья в Вашей жизни вообще' },
  ]);

  const questionsROCS = ref([
    { question: 'Головные или другие боли', answer: -1 },
    { question: 'Плохое самочувствие', answer: -1 },
    { question: 'Нервность, раздражение', answer: -1 },
    { question: 'Повышенная утомляемость, усталость', answer: -1 },
    { question: 'Бессонница, плохой сон', answer: -1 },
    { question: 'Депрессия, плохое настроение', answer: -1 },
    { question: 'Беспричинно повышенное настроение', answer: -1 },
    { question: 'Сухое алкогольное опьянение', answer: -1 },
    { question: 'Алкогольные навязчивости (иллюзии)', answer: -1 },
    { question: 'Алкогольные сновидения', answer: -1 },
    { question: 'Просыпался "как с похмелья"', answer: -1 },
    { question: 'Стал больше курить (чем до лечения)', answer: -1 },
    { question: 'Стал больше пить кофе, чай', answer: -1 },
    { question: 'Беспокойство, волнение (тревога)', answer: -1 },
    { question: 'Тяга к алкоголю', answer: -1 },
  ]);

  const fetchRegSurveyData = async () => {
    try {
      // const userId = localStorage.getItem('userId');
      // if (!userId) return;
      store.loading = true
      const response = await API.fetchRegSurveyData(userStore.userData._id);
      surveys.value = response;
    } catch (error) {
      console.error('Error fetching regular survey data:', error);
    } finally {
      store.loading = false
    }
  };

  const RegSurveysLast = async () => {
    try {
      store.loading = true
      // const userId = localStorage.getItem('userId');
      // if (!userId) return;
      const response = await API.fetchRegSurveyDataLast(userStore.userData._id);
      const registrationDate = new Date(response.registrationDate);
      const currentDate = new Date();
      const daysSinceRegistration = Math.floor((currentDate - registrationDate) / (1000 * 60 * 60 * 24));

      const qualityOfLifeSurveyInterval = 30;
      const daysSinceLastQualityOfLifeSurvey = response.qualityOfLifeSurvey
        ? Math.floor((currentDate - new Date(response.qualityOfLifeSurvey)) / (1000 * 60 * 60 * 24))
        : qualityOfLifeSurveyInterval;

      if (daysSinceRegistration >= qualityOfLifeSurveyInterval && daysSinceLastQualityOfLifeSurvey >= qualityOfLifeSurveyInterval) {
        regularSurvey.value = false;
        category.value = 'QualityOfLife';
        return;
      }

      if (userStore.userData.role !== 'relative') {
        const rocsSurveyIntervals = [30, 60, 90, 185, 365];
        const daysSinceLastRocsSurvey = response.rocsSurvey
          ? Math.floor((currentDate - new Date(response.rocsSurvey)) / (1000 * 60 * 60 * 24))
          : rocsSurveyIntervals[0];
  
        for (let i = 0; i < rocsSurveyIntervals.length; i++) {
          const interval = rocsSurveyIntervals[i];
          const prevInterval = rocsSurveyIntervals[i - 1];
  
          if (daysSinceRegistration >= interval && daysSinceLastRocsSurvey >= prevInterval && daysSinceLastRocsSurvey < interval) {
            regularSurvey.value = false;
            category.value = 'ROCS';
            return;
          }
        }
      }

      regularSurvey.value = true;
      category.value = '';
    } catch (error) {
      console.error('Error fetching survey data:', error);
      toast.add({
        severity: 'error',
        summary: 'Сессия истекла',
        detail: 'Авторизуйтесь повторно',
        life: 3000,
      })
    } finally {
      store.loading = false
    }
  };

  const Regsurveys = async (dto: any) => {
    try {
      await API.RegsurveysAdd(dto);
      await fetchRegSurveyData();
    } catch (error) {
      console.error('Error adding regular survey:', error);
    }
  };

  const calculateTotalScore = () => {
    return calculateSubjectiveWellBeing() + calculateSocialRoles() + calculateExternalConditions();
  };

  const calculateSubjectiveWellBeing = () => {
    return calculateScaleScore([1, 2, 10]);
  };

  const calculateSocialRoles = () => {
    return calculateScaleScore([3, 4, 5, 8]);
  };

  const calculateExternalConditions = () => {
    return calculateScaleScore([6, 7, 9]);
  };

  const calculateScaleScore = (questionNumbers: number[]) => {
    const scaleQuestions = questionsQuality.value.filter((q, index) => questionNumbers.includes(index + 1));
    return scaleQuestions.reduce((ts, q) => ts + q.answer, 0);
  };

  const calculateROCSscore = () => {
    return questionsROCS.value.reduce((ts, q) => ts + q.answer, 0);
  };

  const formSurveyQualityObject = () => {
    const userId = localStorage.getItem('userId');
    if (!userId) return;

    let curQues = [];
    if (category.value === 'ROCS') {
      curQues = questionsROCS.value;
      if (curQues.some((q) => q.answer === -1)) {
        throw new Error('Ответьте на все вопросы');
      }
    } else {
      curQues = questionsQuality.value;
      if (curQues.some((q) => q.answer === 0)) {
        throw new Error('Ответьте на все вопросы');
      }
    }

    let survey = {};

    if (category.value === 'ROCS') {
      survey = {
        userId,
        category: category.value,
        questions: curQues.map((question) => ({
          question: question.question,
          answer: question.answer,
        })),
        totalScore: calculateROCSscore(),
        subjectiveWellBeingScore: -1,
        socialRolesScore: -1,
        externalConditionsScore: -1,
      };
    } else {
      survey = {
        userId,
        category: category.value,
        questions: curQues.map((question) => ({
          question: question.question,
          answer: question.answer,
        })),
        totalScore: calculateTotalScore(),
        subjectiveWellBeingScore: calculateSubjectiveWellBeing(),
        socialRolesScore: calculateSocialRoles(),
        externalConditionsScore: calculateExternalConditions(),
      };
    }

    return survey;
  };

  return {
    surveys,
    regularSurvey,
    category,
    regSurveyOptionsQuality,
    regSurveyOptionsROCS,
    questionsQuality,
    questionsROCS,
    fetchRegSurveyData,
    RegSurveysLast,
    Regsurveys,
    formSurveyQualityObject,
  };
});