import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import API from '@/ts/API.ts';
import { useMainStore } from '@/stores/index';
import { useUserStore } from '@/stores/user';
import { useSurveyStore } from '@/stores/startingForm';
import { useRegularSurveysStore } from '@/stores/regularSurveys';


export const useAdminStore = defineStore('adminStore', () => {
    
  const store = useMainStore();
  const userStore = useUserStore();
  const surveyStore = useSurveyStore();
  const regularStore = useRegularSurveysStore();
  // получение/обработка различной инфы
  const info = ref({
    registeredUsersLastWeek: 0,
    registeredUsersLastMonth: 0,
    registeredUsersTotal: 0,
    bynAverageExpenses: 0,
    rubAverageExpenses: 0,
  });
  const users = ref([]);
  const user = ref({
    _id: '',
    username: '',
    goal: 'goal1',
    relatives: [],
    lastDate: '',
    registrationDate: '',
    drinking: false,
    weeklyAlcoholExpenditure: 0,
    currency: 'BYN',
    code: '',
    phone: '',
    role: 'user',
  });
  const userGoal = computed(() => store.goals.find((g) => g.value === user.value.goal)?.label || '')
  const newExpensesSum = ref(0);
  const relatives = ref([]);
  const surveys = ref([]);
  const mainSurvey = ref({});
  const questions = ref([]);
  const answers = ref([]);

  const roles = [
    { label: 'Пользователь', value: 'user' },
    { label: 'Менеджер', value: 'manager' },
    { label: 'Админ', value: 'admin' },
    { label: 'Родственник', value: 'relative' },
  ];

  const questionsMainSurvey = ref([]);
  const Question = ref({
    _id: null,
    text: '',
    options: [],
    isMultipleChoice: false,
    isEnabled: true,
  })
  const editQuestionData = ref({
    _id: null,
    text: '',
    options: [],
    isMultipleChoice: false,
    isEnabled: true,
  });
  const fetchAllQuestions = async () => {
    try {
      const response = await API.fetchQuestions();
      if (response) {
        questionsMainSurvey.value = response;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const addQuestion = async (dto) => {
    try {
      const response = await API.addQuestions(dto);
      if (response) {
        await resetQuestion();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const editQuestion = async (_id, dto) => {
    try {
      const response = await API.editQuestion(_id, dto);
      if (response) {
        await resetQuestion();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const deleteQuestion = async (_id) => {
    try {
      const response = await API.deleteQuestion(_id);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const resetQuestion = () => {
    Question.value._id = null;
    Question.value.text = '';
    Question.value.options = [];
    Question.value.isMultipleChoice = false;
    Question.value.isEnabled = true;
  }


  const fetchInfoData = async () => {
    try {
      const response = await API.usersInfo();
      if (response) {
        info.value.registeredUsersLastWeek = response.registeredUsersLastWeek;
        info.value.registeredUsersLastMonth = response.registeredUsersLastMonth;
        info.value.registeredUsersTotal = response.registeredUsersTotal;
        info.value.bynAverageExpenses = response.bynAverageExpenses;
        info.value.rubAverageExpenses = response.rubAverageExpenses;
      }
    } catch (error) {
      // console.error('Error fetching info data:', error);
    }
  };
  
  const fetchUsers = async (params) => {
    try {
      // Выполняем запрос на получение всех пользователей
      const response = await API.fetchUsers(params); 
      users.value = response;
    } catch (error) {
      // console.error('Error fetching users:', error);
    }
  };
  const getUserByPhone = async (phone) => {
    try {
      const response = await API.getUserByPhone(phone); 
      user.value = response;
    } catch (error) {
      // console.error('Error fetching users:', error);
    }
  };
  const fetchMainSurvey = async () => {
    try {
      // Выполняем запрос на получение всех пользователей
      const response = await API.fetchSurveyData(user.value._id); 
      mainSurvey.value = response;
    } catch (error) {
      // console.error('Error fetching users:', error);
    }
  };
  const fetchQuestions = async () => {
    try {
      const response = await API.fetchQuestions(user.value._id);
      const fetchedData = response;
      if (fetchedData) {
        // Ваш код обработки данных
        questions.value = fetchedData;
        answers.value = questions.value.flatMap((question) =>
          question.options.map((option, index) => ({
            // формирование анкеты
            questionId: question._id,
            answerId: index,
            // доп переменные для визуала
            id: `${question._id}-${index}`,
            answerText: option,
            select: false,
            multiple: question.isMultipleChoice,
            selectedAnswers: [],
          }))
        )
      }
    } catch (error) {
      // console.error('Error fetching survey data:', error);
    }
  };
  const fetchSurveys = async () => {
    try {
      // Выполняем запрос на получение всех пользователей
      const response = await API.fetchRegSurveyData(user.value._id); 
      surveys.value = response;
    } catch (error) {
      // console.error('Error fetching users:', error);
    }
  };
  const fetchRelatives = async (phone) => {
    try {
      // Выполняем запрос на получение всех пользователей
      const response = await API.fetchRelativesData(phone); 
      relatives.value = response;
    } catch (error) {
      // console.error('Error fetching users:', error);
    }
  };
const addUser = async (phone, role, goal) => {
    try {
      const response = await API.addUser(phone, role, goal); 
      if (response) {
        await fetchUsers({ first: 0 });
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const saveRole = async () => {
    try {
      const response = await API.saveRole({phone: user.value.phone, role: user.value.role}); 
      if (response) {
        user.value.role = response.role
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const saveExpenses = async () => {
    try {
      const response = await API.saveExpenses({phone: user.value.phone, weeklyAlcoholExpenditure: newExpensesSum.value, currency: user.value.currency}); 
      if (response) {
        user.value.weeklyAlcoholExpenditure = response.weeklyAlcoholExpenditure
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const stopDrinking = async () => {
    try {
      const response = await API.stopDrinking(user.value._id);
      if (response) {
        user.value.drinking = response.drinking
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    // datas
    info,
    users,
    user,
    userGoal,
    relatives,
    surveys,
    mainSurvey,
    questions,
    answers,

    questionsMainSurvey,
    Question,
    editQuestionData,
    newExpensesSum,

    // fns
    fetchAllQuestions,
    addQuestion,
    editQuestion,
    deleteQuestion,

    fetchInfoData,
    fetchUsers,
    addUser,
    getUserByPhone,
    fetchMainSurvey,
    fetchQuestions,
    fetchSurveys,
    fetchRelatives,

    saveRole,
    saveExpenses,
    stopDrinking,
    
    // static props
    roles,
  }
})