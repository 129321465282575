import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useMainStore = defineStore('mainStore', () => {
  const loading = ref(false);
  
  const goals = ref([
    { label: 'Поддерживать здоровую трезвую жизнь', value: 'goal1', disabled: false },
    // { label: 'Контролировать употребление', value: 'goal2', disabled: true },
    // { label: 'Прекратить полностью употреблять алкоголь', value: 'goal3', disabled: true },
    // { label: 'Снизить дозу употребления алкоголя', value: 'goal4', disabled: true },
    { label: 'Поддержать трезвость близкого', value: 'goal-relative', disabled: false },
  ]);

  const currencyOptions = ref([
    { label: 'BYN', value: 'BYN' },
    { label: 'RUB', value: 'RUB' }
  ]);
  const degrees = ref([
    { label: 'Мать', value: 'Мать' },
    { label: 'Отец', value: 'Отец' },
    { label: 'Жена', value: 'Жена' },
    { label: 'Брат', value: 'Брат' },
    { label: 'Сестра', value: 'Сестра' },
    { label: 'Другое', value: 'Другое' },
  ])

  const steps = ref([
    { min: 0, max: 24, step: 1, label: 'Менее суток', progress: '', icon: '' },
    { min: 24, max: 36, step: 1, label: '24 часа', progress: 'Исчезновение запаха перегара', icon: '' },
    { min: 36, max: 2 * 24, step: 1, label: '36 часов', progress: 'Исчезновение симптомов похмелья', icon: '' },
    { min: 2 * 24, max: 3 * 24, step: 1, label: '2 дня', progress: 'Снижение вероятности эпилептического приступа', icon: '' },
    { min: 3 * 24, max: 4 * 24, step: 1, label: '3 дня', progress: 'Спадение отёка лица', icon: '' },
    { min: 4 * 24, max: 5 * 24, step: 1, label: '4 дня', progress: 'Прекращения абстинентного синдрома (после запоя)', icon: '' },
    { min: 5 * 24, max: 6 * 24, step: 1, label: '5 дней', progress: 'Улучшение состояния кожи', icon: '' },
    { min: 6 * 24, max: 7 * 24, step: 1, label: '6 дней', progress: 'Улучшения качества сна', icon: '' },
    { min: 7 * 24, max: 14 * 24, step: 1, label: '1 неделя', progress: 'Восстановления эмоционального фона', icon: '' },
    { min: 14 * 24, max: 21 * 24, step: 1, label: '2 недели', progress: 'Восстановление работоспособности', icon: '' },
    { min: 21 * 24, max: 30 * 24, step: 1, label: '3 недели', progress: 'Нормализация давления и ритма сердца', icon: '' },
    { min: 30 * 24, max: 2 * 30 * 24, step: 24, label: '1 месяц', progress: 'Восстановление ясности ума', icon: 'steps/1month.png' },
    { min: 2 * 30 * 24, max: 3 * 30 * 24, step: 24, label: '2 месяца', progress: 'Полное очищение крови от этанола и продуктов распада', icon: 'steps/1month.png' },
    { min: 3 * 30 * 24, max: 4 * 30 * 24, step: 24, label: '3 месяца', progress: 'Полное обновление сперматозоидов', icon: 'steps/3month.png' },
    { min: 4 * 30 * 24, max: 5 * 30 * 24, step: 24, label: '4 месяца', progress: 'Восстановление функций желудка', icon: 'steps/3month.png' },
    { min: 5 * 30 * 24, max: 6 * 30 * 24, step: 24, label: '5 месяцев', progress: 'Восстановление функций поджелудочной железы', icon: 'steps/3month.png' },
    { min: 6 * 30 * 24, max: 7 * 30 * 24, step: 24, label: '6 месяцев', progress: 'Восстановление функций кишечника', icon: 'steps/6month.png' },
    { min: 7 * 30 * 24, max: 8 * 30 * 24, step: 24, label: '7 месяцев', progress: 'Восстановления функций лёгких', icon: 'steps/6month.png' },
    { min: 8 * 30 * 24, max: 9 * 30 * 24, step: 24, label: '8 месяцев', progress: 'Восстановления функций сердца', icon: 'steps/6month.png' },
    { min: 9 * 30 * 24, max: 10 * 30 * 24, step: 24, label: '9 месяцев', progress: 'Восстановление функций почек', icon: 'steps/6month.png' },
    { min: 10 * 30 * 24, max: 11 * 30 * 24, step: 24, label: '10 месяцев', progress: 'Восстановление функций печени', icon: 'steps/6month.png' },
    { min: 11 * 30 * 24, max: 12 * 30 * 24, step: 24, label: '11 месяцев', progress: 'Восстановление функций мозга', icon: 'steps/6month.png' },
    { min: 12 * 30 * 24, max: 24 * 30 * 24, step: 24, label: '1 год', progress: 'Восстановление организма', icon: 'steps/1year.png' },
    { min: 24 * 30 * 24, max: 36 * 30 * 24, step: 24, label: '2 года', progress: 'Восстановление организма', icon: 'steps/1year.png' },
  ]);

  return {
    loading,

    goals,
    steps,
    currencyOptions,
    degrees,
  }
})