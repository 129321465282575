import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import API from '@/ts/API.ts';
import router from '@/router';
import { useToast } from 'primevue/usetoast'
import { useMainStore } from '@/stores'
import { useSurveyStore } from '@/stores/startingForm.ts'
import { useRegularSurveysStore } from '@/stores/regularSurveys.ts'

export const useUserStore = defineStore('userStore', () => {
  const store = useMainStore()
  const toast = useToast()
  const surveyStore = useSurveyStore()
  const regularStore = useRegularSurveysStore()
  const userData = ref({
    _id: '',
    username: '',
    goal: 'goal1',
    lastAlcoholUsage: '',
    registrationDate: '',
    drinking: false,
    weeklyAlcoholExpenditure: 0,
    currency: 'BYN',
    code: '',
    phone: '',
    role: '',
    pushToken: '',
    myData: true,
  });

  const saved = ref(true);

  const isVerified = ref(false);

  const currentGoal = computed(() => store.goals.find((g) => g.value === userData.value.goal)?.label || '')

  const getVerifyCode = async (dto: any) => {
    try {
      return await API.getVerifyCode(dto);
    } catch (error) {
      console.error('Error getting verification code:', error);
    }
  };

  // const verifyCode = async (phone: string, code: string) => {
  //   try {
  //     const response = await API.verifyCode({ phone, confirmationCode: code });
  //     if (response.success) {
  //       userData.value = { ...response.user };
  //       localStorage.setItem('userId', response.user.id);
  //       localStorage.setItem('accessToken', response.accessToken);
  //       localStorage.setItem('phone', phone);
  //       router.push({ name: 'main' });
  //     }
  //   } catch (error) {
  //     console.error('Error verifying code:', error);
  //   }
  // };
  const verifyCode = async (phone, confirmationCode) => {
    try {
      const replacedPhone = phone.replace(/\s/g, '')
      const response = await API.verifyCode({
        phone: replacedPhone,
        confirmationCode: confirmationCode,
      });
      if (response.success) {
        userData.value = { ...response.user };
        userData.value.phone = replacedPhone;
        localStorage.setItem('userId', response.userId);
        localStorage.setItem('accessToken', response.accessToken);
        localStorage.setItem('phone', replacedPhone);
        router.push({ name: 'main' });
      }
    } catch (error) {
      // console.error('verifyCode Error:', error);
      throw error;
    }
  };
  const performVerification = async () => {
      try {
        await verifyCode(userData.value.phone, userData.value.code);
      } catch (error) {
        // console.error('performVerification Error:', error);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userId');
        throw error;
      } finally {
        userData.value.code = '';
        isVerified.value = true;
      }
  };

  const logout = () => {
    userData.value = {
      _id: '',
      username: '',
      goal: 'goal1',
      lastAlcoholUsage: '',
      registrationDate: '',
      drinking: false,
      weeklyAlcoholExpenditure: 0,
      currency: 'BYN',
      code: '',
      phone: '',
      role: '',
      pushToken: '',
    };
    localStorage.removeItem('userId');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('phone');
    router.push({ name: 'auth' });
  };

  const fetchUserData = async (additional = true) => {
    try {
      if (additional) store.loading = true
      const phone = localStorage.getItem('phone');
      if (!phone) return;
      const response = await API.fetchUserData(phone);
      if (response) {
        userData.value = { ...response };
        if (userData.value.username === '' || userData.value.weeklyAlcoholExpenditure === 0) {
          saved.value = false;
        }
        if(additional) {
          if (userData.value.role !== 'relative') surveyStore.fetchSurveyData();
          regularStore.fetchRegSurveyData();
        }
        regularStore.RegSurveysLast();
      } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('phone');
        router.push({ name: 'login' });
        toast.add({
          severity: 'error',
          summary: 'Сессия истекла',
          detail: 'Авторизуйтесь повторно',
          life: 3000,
        })
      }
    } catch (error) {
      if (error === 'Unauthorized') {
        logout()
      }
      // console.error('Error fetching user data:', error);
    } finally {
      store.loading = false
    }
  };

  const resetLastData = async () => {
    try {
      const response = await API.resetLastData(userData.value._id);
      userData.value.lastAlcoholUsage = response.lastAlcoholUsage;
      userData.value.drinking = response.drinking;
    } catch (error) {
      console.error('Error resetting last data:', error);
    }
  };
  const onVerge = async () => {
    try {
      await API.onVerge(userData.value._id);
    } catch (error) {
      console.error('Error resetting last data:', error);
    }
  };

  const stopDrinking = async () => {
    try {
      const response = await API.stopDrinking(userData.value._id);
      userData.value.lastAlcoholUsage = response.lastAlcoholUsage;
      userData.value.drinking = response.drinking;
    } catch (error) {
      console.error('Error stopping drinking:', error);
    }
  };

  const saveLastData = async (dto: any) => {
    try {
      const response = await API.saveData(userData.value._id, dto);
      userData.value.lastAlcoholUsage = response.lastAlcoholUsage;
      userData.value.username = response.username;
      userData.value.weeklyAlcoholExpenditure = response.weeklyAlcoholExpenditure;
      userData.value.currency = response.currency;
      saved.value = true;
    } catch (error) {
      console.error('Error saving last data:', error);
    }
  };

  const isAdmin = () => {
    return userData.value.role === 'admin' || userData.value.role === 'manager';
  };

  return {
    userData,
    
    currentGoal,

    getVerifyCode,
    performVerification,
    verifyCode,

    logout,
    isAdmin,

    fetchUserData,
    resetLastData,
    onVerge,

    stopDrinking,
    saveLastData,
    saved,
  };
});