import { createRouter, createWebHistory } from 'vue-router';
import Admin from '@/views/Admin.vue';
import { useMainStore } from '@/stores/index.js';
import { useUserStore } from '@/stores/user.ts';


const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: () => import('@/layout/AppLayout.vue'),
            beforeEnter: async (to, from, next) => {
                const store = useMainStore();
                const userStore = useUserStore();

                const isLogin = localStorage.getItem('accessToken');

                // if (store.userData.phone == '') {
                store.loading = true;
                await userStore.fetchUserData();
                store.loading = false;
                // }

                isLogin ? next() : next('/auth');
            },
            children: [
                {
                    path: '',
                    name: 'main',
                    meta: {
                        // breadcrumb: ['Моя цель', 'Поддержать здоровый образ жизни']
                    },
                    component: () => import('@/views/pages/MainPage.vue')
                },
                {
                    path: '/relatives',
                    name: 'relatives',
                    meta: {
                        // breadcrumb: ['Моя цель', 'Banking']
                    },
                    component: () => import('@/views/pages/Relatives.vue')
                },
                {
                    path: '/profile',
                    name: 'Profile',
                    meta: {
                        // breadcrumb: ['Моя цель', 'Banking']
                    },
                    component: () => import('@/views/pages/Profile.vue')
                },
                {
                    path: '/settings',
                    name: 'settings',
                    component: () => import('@/views/pages/Settings.vue')
                },
                {
                    path: '/faq',
                    name: 'faq',
                    component: () => import('@/views/pages/Faq.vue')
                },
                // {
                //     path: '/profile',
                //     name: 'profile',
                //     component: () => import('@/views/pages/Profile.vue')
                // },

                {
                    path: '/what-to-remember',
                    name: 'WhatToRemember',
                    component: () => import('@/views/pages/static/WhatToRemember.vue')
                },
                {
                    path: '/benefits-of-sobriety',
                    name: 'BenefitsOfSobriety',
                    component: () => import('@/views/pages/static/BenefitsOfSobriety.vue')
                },
                {
                    path: '/improvement-of-health',
                    name: 'ImprovementOfHealth',
                    component: () => import('@/views/pages/static/ImprovementOfHealth.vue')
                },
                {
                    path: '/useful-tips',
                    name: 'UsefulTips',
                    component: () => import('@/views/pages/static/UsefulTips.vue')
                },
                {
                    path: '/alcohol-myths',
                    name: 'AlcoholMyths',
                    component: () => import('@/views/pages/static/AlcoholMyths.vue')
                },

                {
                    path: '/advice',
                    name: 'Advice',
                    component: () => import('@/views/pages/static/Advice.vue')
                },
                {
                    path: '/craving-appeared',
                    name: 'CravingAppeared',
                    component: () => import('@/views/pages/static/CravingAppeared.vue')
                },
                {
                    path: '/potential-relapse',
                    name: 'PotentialRelapse',
                    component: () => import('@/views/pages/static/PotentialRelapse.vue')
                }
            ]
        },
        { path: '/privacy', name: 'privacy', component: () => import('@/views/pages/static/Privacy.vue') },
        { path: '/logout', name: 'logout', component: () => import('@/views/Logout.vue') },
        { path: '/register', name: 'home', component: () => import('@/views/pages/Home.vue') },
        { path: '/auth', name: 'auth', component: () => import('@/views/pages/Home.vue') },
        {
            path: '/admin',
            name: 'admin',
            component: () => import('@/layout/AppLayout.vue'),
            beforeEnter: async (to, from, next) => {
                // const store = useMainStore();
                const userStore = useUserStore();
                if (userStore.userData.phone == '') {
                    await userStore.fetchUserData();
                }
                const isAdmin = await userStore.isAdmin();

                // Если пользователь не администратор, перенаправьте его обратно на /
                isAdmin ? next() : next('/');
            },
            children: [
                {
                    path: '',
                    name: 'admin.index',
                    component: () => import('@/views/Admin.vue')
                },
                {
                    path: 'users',
                    name: 'admin.users',
                    meta: {
                        // breadcrumb: ['Моя цель', 'Поддержать здоровый образ жизни']
                    },
                    component: () => import('@/views/admin/Users.vue')
                },
                {
                    path: '/admin/users/:userPhone',
                    name: 'userProfile',
                    component: () => import('@/views/admin/UserProfile.vue'),
                    props: true
                },
                {
                    path: 'faq',
                    name: 'admin.faq',
                    meta: {
                        // breadcrumb: ['Моя цель', 'Поддержать здоровый образ жизни']
                    },
                    component: () => import('@/views/admin/Faq.vue')
                },
                {
                    path: 'questions',
                    name: 'admin.questions',
                    meta: {
                        // breadcrumb: ['Моя цель', 'Поддержать здоровый образ жизни']
                    },
                    component: () => import('@/views/admin/Questions.vue')
                },
                {
                    path: 'settings',
                    name: 'admin.settings',
                    meta: {
                        // breadcrumb: ['Моя цель', 'Поддержать здоровый образ жизни']
                    },
                    component: () => import('@/views/admin/Settings.vue')
                }
            ]
        },
        {
            path: '/:catchAll(.*)*',
            name: 'error',
            component: () => import('@/views/error.vue')
        }
    ]
});

// router.beforeEach(async (to, from, next) => {
//     const store = useMainStore();

//     const isLogin = localStorage.getItem('accessToken');
//     isLogin ? next() : next('/auth');
//     if (isLogin) {
//         await store.fetchUserData();
//     }

//     if (to.path === '/admin') {
//         const isAdmin = await store.isAdmin();
//         isAdmin ? next() : next('/');
//     }
// });

export default router;
