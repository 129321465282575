<script setup>
import { computed } from 'vue'
import { useDevice } from 'next-vue-device-detector'
import MobileApp from './components/MobileApp.vue'
import { useRouter } from 'vue-router'
const device = useDevice()
const router = useRouter()
// Вынесена ли закладка в iphone на рабочий стол?
const isIphone = computed(() => (!device.android && (device.iphone || device.iphoneX || device.iphoneXR || device.iPhoneXSMax)) && (window.navigator.userAgent.indexOf('iPhone') !== -1 && window.navigator.standalone !== true))
const isAndroid = computed(() => device.android)
const isOnHomescreenAndroid = computed(() => window.matchMedia('(display-mode: standalone)').matches)
const dateUpdated = new Date('2024-07-21T00:00:00').getTime()

if (!window.localStorage.getItem('lastUpdate')) {
  window.localStorage.setItem('lastUpdate', new Date('2024-05-17T00:00:00').getTime().toString())
  router.replace({ name: 'logout' })
} else if (dateUpdated > parseInt(window.localStorage.getItem('lastUpdate'))){
  window.localStorage.setItem('lastUpdate', dateUpdated.toString())
}
</script>

<template>
  <MobileApp
    v-if="isIphone || (isAndroid && !isOnHomescreenAndroid)"
    :is-android="isAndroid"
    :is-iphone="isIphone"
  />
  <router-view />
</template>

<style scoped></style>
