<script setup lang="ts">
import { computed, useSlots } from 'vue'

const slots = useSlots()

const props = defineProps<{
  variant: 'success' | 'warning' | 'info' | 'error',
  icon: string
}>()

const color = computed(() => {
  switch (props.variant) {
    case 'success': return 'green'
    case 'warning': return 'yellow'
    case 'error': return 'pink'
    default: return 'blue'
  }
})
</script>
<template>
  <div :class="['flex align-items-start p-4 border-round border-1', `bg-${color}-100`, `border-${color}-300`]">
    <i
      v-if="props.icon"
      :class="['pi text-2xl mr-3', props.icon, `text-${color}-900`]"
    />
    <div>
      <div
        v-if="slots.title"
        :class="['font-medium text-xl mb-2 line-height-1', `text-${color}-900`]"
      >
        <slot name="title" />
      </div>
      <div :class="['m-0 p-0 line-height-3', `text-${color}-800`]">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped></style>